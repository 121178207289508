// Reuse most styles from tokenExpirationModal.scss
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;

  .modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 90%;
    animation: modalFadeIn 0.3s ease-out;

    .modal-buttons {
      display: flex;
      gap: 1rem;
      justify-content: center;
      flex-wrap: wrap;

      button {
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s;
        min-width: 120px;

        &:hover {
          opacity: 0.9;
          transform: translateY(-1px);
        }

        &.login-button, &.signup-button {
          background-color: #00BFA6;
          color: white;
        }

        &.cancel-button {
          background-color: #e0e0e0;
          color: #333;
        }
      }
    }
  }
} 