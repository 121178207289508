// Enhanced Variables
$primary-gradient: linear-gradient(135deg, #00b894, #00cec9);
$secondary-gradient: linear-gradient(135deg, #6c5ce7, #a367dc);
$card-bg: #ffffff;
$card-shadow: rgba(0, 0, 0, 0.1);
$text-color: #2d3436;
$secondary-text-color: #636e72;
$button-hover-color: #00b894;
$error-color: #ff7675;
$success-color: #00b894;

// Global Styles
body {
  font-family: 'Arial', sans-serif; // Use a clean font
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.quiz-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9; // Light background
  padding: 20px;
}

.quiz-card {
  background-color: $card-bg;
  border-radius: 16px;
  box-shadow: 0 4px 20px $card-shadow;
  padding: 30px;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s;

  &.start-card {
    .quiz-intro {
      padding: 20px;

      h1 {
        color: $text-color;
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
      }

      .quiz-info {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 1rem;
        margin: 2rem 0;

        .info-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 1rem;
          background: rgba(0, 184, 148, 0.1);
          border-radius: 12px;

          .icon {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  &:hover {
    transform: translateY(-5px); // Subtle lift effect on hover
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); // Enhanced shadow on hover
  }
}

.quiz-wrapper-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.start-button {
  background: $primary-gradient; // Use gradient for button
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px; // Increased padding for a more substantial button
  cursor: pointer;
  font-size: 18px; // Larger font size for better readability
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: $button-hover-color; // Change to hover color
    transform: scale(1.05); // Slightly enlarge on hover
  }
}

.quiz-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: $text-color;
  font-weight: bold; // Make header text bold
}

.question-text {
  font-size: 22px; // Larger font for question text
  margin-bottom: 15px;
  color: $text-color;
}

.quiz-options {
  display: flex;
  flex-direction: column; // Stack options vertically
  gap: 10px; // Space between options
}

.quiz-option {
  background-color: $secondary-text-color; // Use secondary color for options
  border: none;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &.selected {
    background: $primary-gradient; // Use gradient for selected option
    color: white;
  }

  &:hover {
    background-color: lighten($secondary-text-color, 10%);
    transform: scale(1.02); // Slightly enlarge on hover
  }
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
  gap: 1rem;

  .reset-btn {
    background: #ff7675;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover:not(:disabled) {
      background: #ff5e5b;
      transform: translateX(-5px);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .next-btn {
    background: $primary-gradient;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover:not(:disabled) {
      transform: translateX(5px);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

// Animation
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Loading State
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: $secondary-text-color;
  font-size: 1.2rem;
}

// Enhanced Progress Bar
.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #eee;
  border-radius: 4px;
  margin-bottom: 1rem;
  overflow: hidden;

  .progress {
    height: 100%;
    background: $primary-gradient;
    transition: width 0.3s ease;
  }
}

// Enhanced Quiz Info Bar
.quiz-info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  
  .question-counter {
    font-weight: 600;
    color: $text-color;
  }

  .timer {
    background: $secondary-gradient;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: 600;
    transition: background 0.3s ease;

    &.warning {
      background: linear-gradient(135deg, #ff7675, #d63031);
    }

    &.critical {
      background: linear-gradient(135deg, #d63031, #b71c1c);
      animation: pulse 1s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

// Enhanced Question Card
.question-card {
  padding: 2rem;
  border-radius: 12px;
  background: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);

  .question-text {
    font-size: 1.3rem;
    color: $text-color;
    margin-bottom: 2rem;
    line-height: 1.5;
  }
}

// Enhanced Options Grid
.options-grid {
  display: grid;
  gap: 1rem;
  margin: 2rem 0;

  .option-button {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background: white;
    border: 2px solid #eee;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;

    &:hover {
      border-color: #00b894;
      transform: translateX(5px);
    }

    &.selected {
      background: $primary-gradient;
      color: white;
      border-color: transparent;
    }

    .option-label {
      background: #f0f0f0;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      font-weight: 600;
    }

    .option-text {
      flex: 1;
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .quiz-card {
    margin: 10px;
    padding: 20px;
  }

  .question-text {
    font-size: 1.1rem;
  }

  .options-grid {
    gap: 0.8rem;
  }

  .quiz-info {
    grid-template-columns: 1fr !important;
  }
}

// Add error toast styling
.error-toast {
  background: $error-color;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

// Loading overlay
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
  p {
    margin-top: 1rem;
    color: $text-color;
    font-weight: 500;
  }
}

// Loading spinner
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid $primary-gradient;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// Enhanced saving indicator
.saving-indicator {
  display: inline-flex;
  align-items: center;
  color: $secondary-text-color;
  font-size: 0.9rem;
  
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: $success-color;
    border-radius: 50%;
    margin-right: 0.5rem;
    animation: pulse 1s infinite;
  }
}

@keyframes pulse {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

// Enhanced button states
.next-button {
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none !important;
  }

  &:not(:disabled):hover {
    transform: translateX(5px);
  }
}
