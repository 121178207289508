// signup.scss
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1240px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.signup {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #00b894, #00cec9);
}

.signup-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.signup-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  padding: 25px 40px;
}

.signup-form {
  width: 100%;
}

.signup-title {
  font-size: 32px;
  color: #2d3436;
  font-weight: 600;
  text-align: center;
}
.signup-subtitle {
  color: #666;
  text-align: center;
  margin-bottom: 30px;
  font-size: 15px;
}

.form-group {
  margin-bottom: 20px;

  label {
    display: block;
    color: #2d3436;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }
}

.input-wrapper {
  position: relative;

  input {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 15px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;

    &::placeholder {
      color: #999;
    }

    &:focus {
      border-color: #00b894;
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 184, 148, 0.1);
    }
  }
}

.password-toggle {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #00b894;
  }
}

.signup-button {
  width: 100%;
  height: 48px;
  background: linear-gradient(135deg, #00b894, #00cec9);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
//   margin-top: 10px;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 184, 148, 0.2);
  }

  &:active {
    transform: translateY(0);
  }
}

.signup-links {
  margin-top: 15px;
  text-align: center;
}

.forgot-password-link {
  color: #00b894;
  text-decoration: none;
  font-size: 14px;
  
  &:hover {
    text-decoration: underline;
  }
}

.login-link {
  margin-top: 15px;
  color: #666;
  font-size: 14px;

  a {
    color: #00b894;
    text-decoration: none;
    margin-left: 5px;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .signup-card {
    padding: 30px 20px;
  }

  .signup-title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  .input-wrapper input {
    height: 44px;
  }

  .signup-button {
    height: 44px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 15px;
  }

  .signup-card {
    padding: 25px 15px;
  }

  .signup-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}