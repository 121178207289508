.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: white;
  border-right: 1px solid rgb(230, 239, 245);
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
  z-index: 1000; // Reduced z-index to be lower than modal
  &.collapsed {
    width: 80px;
    
    .sidebar-logo {
      padding: 1rem 0;
      justify-content: center;
      
      span {
        display: none;
      }
    }
    
    .sidebar-item {
      // padding: 0.75rem;
      
      .item-content span {
        display: none;
      }
    }
  }

  .sidebar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1rem 0;
  }

  .sidebar-logo {
    // padding-top: 5px;
    display: flex;
    align-items: center;
    // padding: 1rem 1.5rem;
    justify-content: center;
    margin-bottom: 30px;
    
    span {
      background: linear-gradient(135deg, #00b894, #00cec9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.75rem;
      font-weight: 900;
      white-space: nowrap;
    }
  }

  .sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .sidebar-item {
    text-decoration: none;
    color: rgb(105, 111, 121);
    padding: 0.75rem 1.5rem;
    transition: all 0.3s ease;
    
    &:hover {
      background-color: rgb(245, 245, 245);
    }
    
    .item-content {
      display: flex;
      align-items: center;
      gap: 1rem;
      
      img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
      }
      
      span {
        font-size: 1rem;
        white-space: nowrap;
      }
    }
    
    &.active-link {
      font-weight: 600;
      background: rgb(245, 245, 245);
      border-left: 4px solid #00cec9;
      
      .item-content {
        background: linear-gradient(135deg, #00b894, #00cec9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        img {
          filter: invert(60%) sepia(98%) saturate(380%) hue-rotate(130deg) brightness(91%) contrast(94%);
        }
      }
    }
  }

  .sidebar-toggle {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 36px;
    background-color: #00cec9;
    border: none;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
    &:hover {
      background-color: #00b894;
    }
    
    svg {
      width: 16px;
      height: 16px;
    }
  }
}