// profile.scss
.profile {
  position: relative;
  &-trigger {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #64748b;
    
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &-dropdown {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 320px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.2s;
    
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &-header {
    padding: 16px;
    display: flex;
    gap: 12px;
    border-bottom: 1px solid #e2e8f0;

    .profile-avatar {
      width: 64px;
      height: 64px;
    }

    &-info {
      h3 {
        font-weight: 600;
        margin: 0 0 4px 0;
      }

      p {
        color: #64748b;
        font-size: 14px;
        margin: 0;
      }
    }
  }

  &-stats {
    padding: 16px;
    border-bottom: 1px solid #e2e8f0;

    h4 {
      margin: 0 0 12px 0;
      font-size: 14px;
      font-weight: 600;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
    }

    &-item {
      background-color: #f8fafc;
      padding: 12px 8px;
      border-radius: 8px;
      text-align: center;

      .icon {
        width: 20px;
        height: 20px;
        margin: 0 auto 4px;
      }

      .value {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
      }

      .label {
        font-size: 12px;
        color: #64748b;
        margin: 0;
      }
    }
  }

  &-menu {
    padding: 8px;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.2s;
      text-decoration: none;
      color: inherit;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .file-input {
    display: none;
  }
}