// QuizHistory.scss
$primary-gradient: linear-gradient(135deg, #00b894, #00cec9);
$card-bg: #ffffff;
$card-shadow: rgba(0, 0, 0, 0.1);
$text-color: #2d3436;
$secondary-text-color: #636e72;
$button-hover-color: #00b894;

.quiz-history-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.quiz-history-card {
  background-color: $card-bg;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 12px $card-shadow;
  text-align: center;
}

.quiz-history-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 24px;
  color: #00cec9;
}

.quiz-history-item {
  background-color: $card-bg;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px $card-shadow;
}

.quiz-history-item-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.quiz-history-item-data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .quiz-history-item-duration {
    font-size: 1rem;
    color: $secondary-text-color;
  }

  .quiz-history-item-score {
    font-size: 1rem;
    color: $secondary-text-color;
  }
}

.quiz-history-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.view-answers-btn {
  background-color: #00cec9;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 12px;
}

.view-answers-btn:hover {
  background-color: $button-hover-color;
}

.no-history-msg {
  font-size: 1.2rem;
  color: $secondary-text-color;
}

.quiz-history-item-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  width: 100%;
  margin: 10px 0;

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: rgba(0, 206, 201, 0.1);
    border-radius: 8px;

    span {
      font-size: 0.9rem;
      color: $secondary-text-color;
      margin-bottom: 5px;
    }

    strong {
      font-size: 1.2rem;
      color: #00cec9;
    }
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 206, 201, 0.1);
    border-left-color: #00cec9;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #ff7675;
  text-align: center;
  padding: 20px;
  background: rgba(255, 118, 117, 0.1);
  border-radius: 8px;
  margin: 20px 0;
}

.question-review {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin: 15px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &.correct {
    border-left: 4px solid #00b894;
  }

  &.incorrect {
    border-left: 4px solid #ff7675;
  }

  .question-number {
    font-weight: bold;
    color: #2d3436;
    margin-bottom: 10px;
  }

  .question-text {
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: #2d3436;
  }

  .answer-section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .user-answer, .correct-answer {
      span {
        font-weight: bold;
      }
    }

    .user-answer span {
      color: #ff7675;
    }

    .correct-answer span {
      color: #00b894;
    }
  }
}

.back-button {
  background: #636e72;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: #2d3436;
  }
}

.quiz-detail-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.summary-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  .summary-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .summary-label {
    color: #6c757d;
    margin-bottom: 5px;
  }

  .summary-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2d3436;
  }
}

.detail-view {
  background: #f8f9fa;
  border-radius: 15px;
  padding: 30px;
}

.quiz-detail-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.question-card {
  background: white;
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  .question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .question-number {
    font-weight: 600;
    color: #2d3436;
  }

  .question-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #2d3436;
    margin-bottom: 20px;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 8px;
  }
}

.options-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  transition: all 0.2s;

  &.correct-option {
    background: rgba(40, 167, 69, 0.1);
    border-color: #28a745;
  }

  &.wrong-option {
    background: rgba(220, 53, 69, 0.1);
    border-color: #dc3545;
  }

  .option-content {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1;
  }

  .option-label {
    background: #e9ecef;
    padding: 5px 12px;
    border-radius: 4px;
    font-weight: 500;
    min-width: 30px;
    text-align: center;
  }

  .option-text {
    flex: 1;
    line-height: 1.5;
  }

  .option-icon {
    font-size: 1.2rem;
    margin-left: 15px;
  }
}

.status-badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;

  &.correct {
    background: rgba(40, 167, 69, 0.1);
    color: #28a745;
  }

  &.incorrect {
    background: rgba(220, 53, 69, 0.1);
    color: #dc3545;
  }
}

.back-button {
  background: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1rem;

  &:hover {
    background: #495057;
  }
}

.answer-comparison {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.answer-box {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  border-left: 4px solid;

  &.correct {
    border-left-color: #28a745;
    background: rgba(40, 167, 69, 0.05);
  }

  &.incorrect {
    border-left-color: #dc3545;
    background: rgba(220, 53, 69, 0.05);
  }
}

.answer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.answer-label {
  font-weight: 500;
  color: #495057;
}

.answer-indicator {
  font-weight: bold;
  
  &.correct {
    color: #28a745;
  }
  
  &.incorrect {
    color: #dc3545;
  }
}

.answer-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.answer-letter {
  background: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  min-width: 35px;
  text-align: center;
}

.answer-text {
  flex: 1;
  line-height: 1.5;
}
