* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f7fa;
}

.container {
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1440px;
}

ul, ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.navbar {
  background-color: white;
  padding: 15px 0;
  height: 80px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  position: sticky;
  top: 0;
  z-index: 1000;

  .navbar-content {
    display: flex;
    align-items: center !important;
    justify-content: space-between;

    .navbar-logo {
      margin-top: 5px;
      font-size: 28px;
      font-weight: 400;
      background: linear-gradient(135deg, #00b894, #00cec9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: transform 0.2s ease;
      &:hover {
        transform: translateY(-1px);
      }
    }

    .navbar-menu {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    .navbar-searchbar {
      border-radius: 12px;
      background: #f8f9fa;
      width: 280px;
      display: flex;
      padding: 12px 15px;
      transition: all 0.3s ease;
      border: 1px solid transparent;

      img{
        width: 25px;
        filter: invert(60%) sepia(98%) saturate(380%) hue-rotate(130deg) brightness(91%) contrast(94%);
        
      }
      &:hover, &:focus-within {
        background: white;
        border-color: #00b894;
        box-shadow: 0 2px 8px rgba(0,184,148,0.1);
      }

      input {
        border: 0;
        background: none;
        outline: none;
        padding-left: 10px;
        width: 100%;
        font-size: 0.95rem;
        color: #2d3436;

        &::placeholder {
          color: #b2bec3;
        }
      }
    }

    .navbar-settings, .navbar-notifications {
      width: 42px;
      height: 42px;
      background: #f8f9fa;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: white;
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);
        transform: translateY(-2px);
      }
      img {
        width: 22px;
        height: 22px;
        opacity: 0.7;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 1;
        }
      }
    }

    .navbar-profile {
      img {
        width: 42px;
        height: 42px;
        border-radius:50%;
        transition: all 0.3s ease;
        object-fit: cover;
        border: 2px solid transparent;

        &:hover {
          border-color: #00b894;
          transform: translateY(-2px);
          box-shadow: 0 2px 8px rgba(0,184,148,0.2);
        }
      }
    }

    .navbar-login {
      background: linear-gradient(135deg, #00b894, #00cec9);
      width: 110px;
      height: 42px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 600;
      font-size: 0.95rem;
      transition: all 0.3s ease;
      box-shadow: 0 2px 8px rgba(0,184,148,0.2);

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0,184,148,0.3);
      }

      &:active {
        transform: translateY(0);
      }
    }
  }
}

// Settings dropdown
.navbar-settings-group {
  position: relative;
}
.navbar-settings-dropdown {
  border-radius: 6px;
  width: 150px;
  height: 300px;
  display: none;
  position: absolute;
  top: 100%; /* Position dropdown below the navbar-settings */
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  // padding: 10px 15px;
  z-index: 10;
ul{
  li{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    color: rgb(105, 111, 121);
    display: flex;
    align-items: center;
    gap: 5px;
    img{
      width: 20px;
    }
  }
  li:hover{
    cursor: pointer;
    background-color: rgb(245, 245, 245);

  }
  li:nth-child(1){
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  li:nth-child(3){
    color: red;
    font-weight: 500;
  }
}
}
.navbar-settings-group:hover .navbar-settings-dropdown {
  display: block;
}





// NAVBAR NOTIFICATIONS DROPDOWN
/* Notifications Dropdown */
.navbar-notifications-group {
  position: relative;
}

.navbar-notifications {
  width: 42px;
  height: 42px;
  background: #f8f9fa;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.navbar-notifications:hover {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.navbar-notifications img {
  width: 22px;
  height: 22px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.navbar-notifications img:hover {
  opacity: 1;
}

/* Notifications Dropdown Content */
.navbar-notifications-dropdown {
  border-radius: 6px;
  width: 200px;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100px;
  z-index: 10;
  transition: all 0.3s ease;
}

.navbar-notifications-dropdown p {
  text-align: center;
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 0.95rem;
  color: rgb(105, 111, 121);
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.navbar-notifications-dropdown p:hover {
  background-color: #f5f7fa;

}

.navbar-notifications-group:hover .navbar-notifications-dropdown {
  display: block;
}




// SETTINGS MODAL
//* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  height: 300px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Close "X" button in top-right corner */
.modal-close {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-close:hover {
  color: #ff0000; /* Change color when hovering */
}

/* Modal Title */
h2 {
  margin-bottom: 15px;
}

/* Modal Body */
p {
  margin-bottom: 20px;
}




/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/* Modal content */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

/* Close button (X) */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

/* Styling for the login form */
form {
  display: flex;
  flex-direction: column;
}

form label {
  margin-bottom: 8px;
  font-size: 14px;
}

form input {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

form button {
  padding: 10px;
  background-color: #00b894;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #00cec9;
}






// NAVBAR PROFILE DROPDOWN
.navbar-profile-group {
  position: relative;

  .navbar-profile {
    cursor: pointer;
    img {
      width: 40px; // Adjust size as needed
      height: 40px;
      border-radius: 50%;
    }
  }

  .profile-dropdown {
    position: absolute;
    top: 100%; // Place it directly below the profile image
    right: 0; // Align the dropdown to the right edge
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
    opacity: 0; // Initially hidden
    visibility: hidden; // Prevent interaction
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s;

    // Styling for dropdown items
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 8px 12px;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  &:hover .profile-dropdown {
    opacity: 1; // Show dropdown on hover
    visibility: visible;
    transform: translateY(0);
  }
}

