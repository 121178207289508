
.sidebar-navbar-outlet{
    display: flex;
    width: 100%;
    .navbar{
        width: 100% !important;
    }

    .navbar-outlet{
        width: 100%;
        .outlet{
            background-color: rgb(193, 214, 226);
            max-height: calc(100vh - 80px);
            height: 100vh;
            padding-left: 20px;
            padding-top: 20px;
        }
    }
}